// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ayuda-js": () => import("./../../../src/pages/Ayuda.js" /* webpackChunkName: "component---src-pages-ayuda-js" */),
  "component---src-pages-buscador-jsx": () => import("./../../../src/pages/buscador.jsx" /* webpackChunkName: "component---src-pages-buscador-jsx" */),
  "component---src-pages-carrito-jsx": () => import("./../../../src/pages/Carrito.jsx" /* webpackChunkName: "component---src-pages-carrito-jsx" */),
  "component---src-pages-conocenos-jsx": () => import("./../../../src/pages/Conocenos.jsx" /* webpackChunkName: "component---src-pages-conocenos-jsx" */),
  "component---src-pages-contactanos-jsx": () => import("./../../../src/pages/Contactanos.jsx" /* webpackChunkName: "component---src-pages-contactanos-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-politicas-jsx": () => import("./../../../src/pages/Politicas.jsx" /* webpackChunkName: "component---src-pages-politicas-jsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-categoria-page-jsx": () => import("./../../../src/templates/categoria-page.jsx" /* webpackChunkName: "component---src-templates-categoria-page-jsx" */),
  "component---src-templates-producto-page-jsx": () => import("./../../../src/templates/producto-page.jsx" /* webpackChunkName: "component---src-templates-producto-page-jsx" */),
  "component---src-templates-segmento-page-jsx": () => import("./../../../src/templates/segmento-page.jsx" /* webpackChunkName: "component---src-templates-segmento-page-jsx" */),
  "component---src-templates-subcategoria-page-jsx": () => import("./../../../src/templates/subcategoria-page.jsx" /* webpackChunkName: "component---src-templates-subcategoria-page-jsx" */)
}

